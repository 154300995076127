/******* Angular Resourse *******/
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BaseApi } from "@api/base/base.service";
import { Filter } from "@core/types";

@Injectable()
export class WebhookLogs extends BaseApi {

    constructor(@Inject(HttpClient) http: HttpClient) {
        super(http);
    }

    /**
     * GET /webhook-logs - get webhook logs
     * 
     * @param {Fitler} filter
     */
    public getWebLogs(filter: Filter) {
        return this.find("webhook-logs", filter);
    }

    /**
     * GET /webhook-logs/count - get webhook logs count
     * 
     * @param where
     */
    public getWebLogsCount(where) {
        return this.count("webhook-logs", where);
    }
}