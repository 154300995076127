import { RolePermission, TenantType, TentTypeBlockPermisson, TentTypeNameMap } from "@core/types";

export const TENANT_TYPE_NAME_MAP: TentTypeNameMap = {
    [TenantType.MSP_PARTNER_ENTERPRISE]: "Enterprise(MSP/Partner)",
    [TenantType.ISP]: "Standard",
    [TenantType.ISP_PLUS]: "Plus",
    [TenantType.ISP_PRO]: "Pro",
    [TenantType.EVAL]: "Eval",
    [TenantType.DEFAULT]: "BEC",
    [TenantType.TRIAL]: "Trial"
};

export const TENANT_TYPE_BLOCK_PERMISSON: TentTypeBlockPermisson = {
    [TenantType.MSP_PARTNER_ENTERPRISE]: [],
    [TenantType.ISP]: [
        RolePermission.FEATURES_REST_API,
        RolePermission.FEATURES_ALERT,
        RolePermission.DEVICE_REMOTE_ACCESS
    ],
    [TenantType.ISP_PLUS]: [
        RolePermission.FEATURES_WEBHOOK,
        RolePermission.FEATURES_REST_API
    ],
    [TenantType.ISP_PRO]: [],
    [TenantType.EVAL]: [],
    [TenantType.DEFAULT]: [],
    [TenantType.TRIAL]: []
};