/******* Angular Resourse *******/
import { Injectable } from '@angular/core';

/******* Shared Resourse *******/
import { UtilsService } from './utils.service';
import { GlobalUIBlockingService } from './global-ui-blocking.service';

/******* Plug-In Resourse *******/
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

/******* API Resourse *******/
import { RfStandard } from '@api';
import { Signal } from '@core/types';
import { SIGNAL_CAP } from '@core/constant';

@Injectable({
    providedIn: 'root',
})
export class RfStandardService {

    // Public
    public currentRfStandard: Observable<Signal | null>;

    // Private
    private currentRfStandardSubject: BehaviorSubject<Signal | null>;

    /**
     * Constructor
     *
     * @param {RfStandard} _rfStandard
     * @param {UtilsService} _utilsService
     */
    constructor(private _rfStandard: RfStandard,
        private _utilsService: UtilsService,
        private _globalUIBlockingService: GlobalUIBlockingService) {

        // Rf standard subject initial
        this.currentRfStandardSubject = new BehaviorSubject<Signal>(null);
        this.currentRfStandard = this.currentRfStandardSubject.asObservable().pipe(tap((x) => { if (!x) this._refreshRfStandard(); }));
    }

    //  Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get current rf standard value
     */
    public get currentRfStandardValue() {
        return this.currentRfStandardSubject.value;
    }

    // Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Query Rf Standard Data
     */
    private _refreshRfStandard(resolve = null, rejects = null): void {
        this._globalUIBlockingService.start();
        this._rfStandard.getRfStandard().subscribe((returnData) => {
            const rfStandard = returnData.data;
            this._globalUIBlockingService.stop();
            this.currentRfStandardSubject.next(_.pick(rfStandard, ['RSRP', 'RSRQ', 'RSSI', 'SINR']));
            if (resolve) resolve(_.pick(rfStandard, ['RSRP', 'RSRQ', 'RSSI', 'SINR']));
        }, () => {
            this._globalUIBlockingService.stop();
            if (rejects) rejects(false);
        });
    }

    /**
     * Update rf standard data
     * 
     * @param {Signal} inputData 
     * @param resolve 
     * @param rejects 
     */
    private _createRfStandard(inputData: Signal, resolve = null, rejects = null): void {
        this._rfStandard.updateRfStandard(inputData).subscribe(() => {
            this._refreshRfStandard(resolve, rejects);
        }, (error) => {
            this._utilsService.alert('error', 'Fail to Create Group Data', error);
            if (resolve) rejects(error);
        });
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh Rf Standard
     * 
     * @returns {Promise<Signal>}
     */
    public refreshRfStandard(): Promise<Signal> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._refreshRfStandard(resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Update rf standard
     * 
     * @param {Signal} inputData
     * @returns {Promise<Signal>} 
     */
    public createRfStandard(inputData: Signal): Promise<Signal> {
        return new Promise((resolve, rejects) => {
            if (sessionStorage.getItem("becentralAccessToken")) this._createRfStandard(inputData, resolve, rejects);
            else rejects("You have loss your Authorization, Please login again!");
        });
    }

    /**
     * Clear the current rf standard data
     */
    public clearAllInfo(): void {
        this.currentRfStandardSubject.next(null);
    }

    /**
     * calculate threshold
     * @param {string} singalType 
     */
    public calculateThreshold(singalType: string): any {
        const currentStandard = this.currentRfStandardSubject.value;
        return currentStandard ? SIGNAL_CAP[singalType].thresholds.map(x => {
            switch (x.comment) {
                case 'Poor':
                    x.less = currentStandard[singalType].midcell;
                    break;
                case 'Ok':
                    x.larger = currentStandard[singalType].midcell;
                    x.less = currentStandard[singalType].good;
                    break;
                case 'Good':
                    x.larger = currentStandard[singalType].good;
                    x.less = currentStandard[singalType].excellent;
                    break;
                case 'Excellent':
                    x.larger = currentStandard[singalType].excellent;
                    break;
            }
            return { ...x }
        }) : SIGNAL_CAP[singalType].thresholds;
    }
}