import { trigger, transition, style, query, group, animateChild, animate, keyframes, state, stagger, sequence } from '@angular/animations';

// Animation: FadeInLeft
export const fadeInLeft = trigger('fadeInLeft', [
	transition('* => *', [
		query(
			':enter, :leave',
			style({ position: 'absolute', left: 0, width: '100%', paddingRight: '2rem', paddingLeft: '2rem', opacity: 0 }),
			{
				optional: true
			}
		),
		query(':enter', style({ transform: 'translateX(-100%)', opacity: 0 }), { optional: true }),

		group([
			query(
				':leave',
				[
					style({ transform: 'translateX(0%)' }),
					animate('600ms ease', style({ opacity: 1, transform: 'translateX(100%)' }))
				],
				{ optional: true }
			),
			query(':enter', [animate('800ms ease', style({ opacity: 1, transform: 'translateX(0%)' })), animateChild()], {
				optional: true
			})
		])
	])
]);

// Animation: ZoomIn
export const zoomIn = trigger('zoomIn', [
	transition('* <=> *', [
		query(
			':enter, :leave',
			[
				style({
					position: 'absolute',
					left: 0,
					width: '100%',
					paddingRight: '2rem',
					paddingLeft: '2rem',
					opacity: 0,
					transform: 'scale(0.5) translateY(-20%)'
				})
			],
			{ optional: true }
		),
		query(
			':enter',
			[
				animate(
					'400ms ease',
					style({ opacity: 1, paddingRight: '2rem', paddingLeft: '2rem', transform: 'scale(1) translateY(0)' })
				)
			],
			{
				optional: true
			}
		)
	])
]);

// Animation: FadeIn
export const fadeIn = trigger('fadeIn', [
	transition('* <=> *', [
		query(
			':enter, :leave',
			[
				style({
					position: 'absolute',
					left: 0,
					width: '100%',
					paddingRight: '2rem',
					paddingLeft: '2rem',
					opacity: 0
				})
			],
			{ optional: true }
		),
		query(':enter', [animate('500ms ease', style({ opacity: 1, paddingRight: '2rem', paddingLeft: '2rem' }))], {
			optional: true
		})
	])
]);

// Animation : Expand and Collapse Smoothly
export const smoothEnterAndLeave = function (MH: string, leaveSmoothly: boolean): any {

	const enter = transition(':enter', [
		style({ maxHeight: '0px' }),
		animate('250ms', style({ maxHeight: MH })),
	]);

	const leave = transition(':leave', [
		style({ maxHeight: MH }),
		animate('200ms', style({ maxHeight: '0px' }))
	])

	let transitionArray = [enter];

	if (leaveSmoothly)
		transitionArray.push(leave);

	return transitionArray;

};

// Animation : Insert / Remove animation
export const InsertRemoveFade = trigger('myInsertRemoveTrigger', [
	transition(':enter', [
		style({ opacity: 0 }),
		animate('200ms', style({ opacity: 1 })),
	]),
	transition(':leave', [
		animate('200ms', style({ opacity: 0 }))
	])
]);

// Animation : Speedtest start / stop animation
export const speedTestAnimation = trigger('speedTestTrigger', [
	state('results', style({
	})),
	state('ready', style({
		height: "10rem",
		width: "10rem",
		borderRadius: "50%",
		marginLeft: "50%",
		transform: "translateX(-50%)",
		fontSize: "2rem"
	})),
	state('running', style({
		opacity: 0
	})),
	transition('results => ready',
		sequence([
			animate('.3s ease-out', style({
				opacity: 1,
				height: "10rem",
				width: "10rem",
				borderRadius: "50%",
				fontSize: "2rem"
			})),
			animate('.5s ease-out', style({
				marginLeft: "50%",
				transform: "translateX(-50%)"
			}))
		])
	),
	transition('ready => running',
		sequence([
			animate('.3s ease-out', style({
				opacity: 0
			}))
		])
	),
	transition('* => results', animate('.5s ease-out'))
]);

// Animation : Speedtest running animation
export const speedTestRunningAnimation = trigger('speedTestRunningTrigger', [
	state('left', style({
		left: "-30%"
	})),
	state('right', style({
		left: "105%"
	})),
	transition('left => right',
		sequence([
			animate('1.2s', style({
				left: "45%"
			})),
			animate('1.2s', style({
				left: "105%"
			}))
		])
	),
	transition('right => left',
		sequence([
			animate('1.2s', style({
				left: "45%"
			})),
			animate('1.2s', style({
				left: "-30%"
			}))
		])
	)
])

export const tenantSwitchingAnimation = trigger('tenantSwitchingTrigger', [
	state('initial', style({
		opacity: 0,
		transform: "translateX(-50%)"
	})),
	state('end', style({
		opacity: 0,
		transform: "translateX(300px)"
	})),
	transition('initial => end',
		sequence([
			animate('.7s', style({
				opacity: 1,
				transform: "translateX(50px)"
			})),
			animate('.7s', style({
				opacity: 0,
				transform: "translateX(200px)"
			}))
		])
	)
]);

// Animation : Button display / hide animation
export const buttonDisplayAnimation = trigger('buttonDisplayTrigger', [
	state('true', style({
		opacity: 1
	})),
	state('false', style({
		opacity: 0,
		marginBottom: "-1rem"
	})),
	transition('false => true',
		sequence([
			animate('.3s ease-out', style({
				opacity: 1,
				marginBottom: "0rem"
			}))
		])
	),
	transition('true => false',
		sequence([
			animate('.3s ease-out', style({
				opacity: 0,
				marginBottom: "-1rem"
			}))
		])
	)
]);
