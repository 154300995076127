/******* Angular Resourse *******/
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BaseApi } from "@api/base/base.service";
import { WebhookSettings as WebhookSettingsType, WebhookTestBody } from "@core/types";

@Injectable()
export class WebhookSettings extends BaseApi {

    constructor(@Inject(HttpClient) http: HttpClient) {
        super(http);
    }

    /**
     * GET /webhook-settings - get webhook settings
     */
    public getWebhookSettings() {

        let method = "GET",
            url = "webhook-settings",
            routeParams = {},
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /webhook-settings - create wehook profile
     * 
     * @param {WebhookSettingsType} data  
     */
    public createWebhookSettings(data: WebhookSettingsType) {

        let method = "POST",
            url = "webhook-settings",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PATCH /webhook-settings/{id} - update wehook profile
     * 
     * @param {string} id
     * @param {WebhookSettingsType} data  
     */
    public updateWebhookSettings(id: string, data: WebhookSettingsType) {

        let method = "PATCH",
            url = "webhook-settings/:id",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /webhook-settings/webhooks - test wehook profile
     * 
     * @param {WebhookTestBody} data  
     */
    public testWebhookSettings(data: WebhookTestBody) {

        let method = "POST",
            url = "webhook-settings/webhooks",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}