export interface CommandDropdownListItem {
    name: string; // command name
    icon: string; // feather icon name
    privilegedId: string; // privileged control id
    key?: string;
};

export interface UpgradeFirmwareCommandBody {
    link: string;
    md5: string;
};

export interface UpgradeConfigurationCommandBody {
    link: string;
};

export interface GetConfigurationCommandBody {
    fileType: "parsing" | "original";
};

export interface RunDiagnosticCommandBody {
    ipaddr: string;
    diagpvc: string;
    pingothertype: boolean;
};

export interface PciLockCommandBody {
    enable: boolean;
    earfcn: string;
    cellid: string;
};

export enum RemoteAccessControl {
    ENABLE = 'enable',
    DISABLE = 'disable'
};

export interface RemoteAccessCommandBody {
    value: RemoteAccessControl;
};

export interface CbsdCommandBody {
    command: DeviceCommand.CBSD_DEREGISTER | DeviceCommand.CBSD_RELINQUISHMENT | DeviceCommand.CBSD_GRANT_RENEW
};

export interface CbsdSetCpiPrivateKeyCommandBody {
    value: string;
    node: string;
    attribute: string;
};

export enum OpenWrtConfigModules {
    AEE = 'aee',
    AUDIO = 'audio',
    COLLECTD = 'collectd',
    CUSTOM = 'custom',
    DDNS = 'ddns',
    DHCP = 'dhcp',
    DROPBEAR = 'dropbear',
    EASYCWMP = 'easycwmp',
    ETHERNET = 'ethernet',
    FIREWALL = 'firewall',
    IGMP_PROXY = 'igmpproxy',
    LED_CTRLD = 'led_ctrld',
    LUCI = 'luci',
    MDLOG = 'mdlog',
    MODEM = 'modem',
    MWAN3 = 'mwan3',
    NET_AGENT = 'netagent',
    NETWORK = 'network',
    NTP_CLIENT = 'ntpclient',
    OPENVPN = 'openvpn',
    PROFILE = 'profile',
    QOS = 'qos',
    RPCD = 'rpcd',
    SAMBA4 = 'samba4',
    SCD = 'scd',
    SIGNALS = 'signals',
    SOCKETMAN = 'socketman',
    SPEECH_PROPERTY = 'speech_property',
    SYS_STAT = 'sysstat',
    SYSTEM = 'system',
    TTL = 'ttl',
    UCI_TRACK = 'ucitrack',
    UHTTPD = 'uhttpd',
    USB = 'usb',
    VARIABLE = 'variable',
    VNSTAT = 'vnstat',
    VOIP = 'voip',
    VPN_BYPASS = 'vpnbypass',
    WIRELESS = 'wireless',
    WPAD = 'wpad'
};

export interface SetOpenWrtArgument {
    config?: OpenWrtConfigModules | string,
    section: string;
    values: { [key: string]: any };
};

export interface SetOpenWrtObject {
    arguments: SetOpenWrtArgument
};

export interface OpenWrtCommandBody {
    objects: Array<OpenWrtConfigModules | SetOpenWrtObject>;
};

export enum OpenWrtWifiModules {
    WLAN_24_G = 'wlan-2.4g',
    WLAN_5_G = 'wlan-5g',
};

export interface OpenWrtCommandWiFiBody {
    [OpenWrtWifiModules.WLAN_24_G]?: any;
    [OpenWrtWifiModules.WLAN_5_G]?: any
};

export enum UbusCommand {
    LIST = 'list',
    CALL = 'call'
};

export enum UbusObject {
    LUCI = 'luci'
};

export enum UbusMethod {
    SET_PASSWORD = "setPassword"
};

export interface UbusSetPasswordBody {
    username: string;
    passworkd: string;
};

export interface OpenwrtCommandUbusBody {
    command: UbusCommand;
    object: UbusObject;
    method: UbusMethod;
    arguments: UbusSetPasswordBody;
};

export interface UdpTestCommandBody {
    servip_ip?: string;
    port: number;
    udp_size?: number;
    packet_number: number;
    timeout?: number;
};

export enum DeviceCommand {
    REBOOT = 'REBOOT',
    RESTORE = 'SET_FACTORY_REST',
    UPGRADE_FIRMWARE = 'SET_FIRMWARE',
    UPGRADE_CONFIGURATION = 'SET_CONFIG',
    GET_CONFIG = 'GET_CONFIG',
    GET_RF_SURVEY = 'GET_RF_SURVEY',
    PCI_LOCK = 'SET_PCI_LOCK',
    RUN_DIAGNOSTIC = 'GET_DIAGNOSTIC_TOOL',
    GET_DEVICE_INFO = 'GET_DEVICE_INFO',
    RUN_SPEED_TEST = 'GET_SPEED_TEST',
    GET_SYSTEM_LOGS = 'GET_SYSLOG',
    GET_CBSD_LOGS = 'GET_CBSD_LOG',
    SYNC_STATUS = 'SYNC_STATUS',
    REMOTE_ACCESS = 'REMOTE_ACCESS',
    GET_DEVICES = 'GET_DEVICES',
    SET_CBSD_ACTION = 'SET_CBSD_ACTION',
    UDP_TEST_UPLOAD = 'SET_UDP_TX',
    UDP_TEST_DOWNLOAD = 'GET_UDP_RX',
    /* cbsd command */
    CBSD_DEREGISTER = 'Deregister',
    CBSD_RELINQUISHMENT = 'Relinquishment',
    CBSD_GRANT_RENEW = 'grantRenew',
    SET_CPI_PRIVATE_KEY = 'SET_CPI_PRIVATE_KEY',
    /* openwrt command */
    SET_OPENWRT_MULTI_CONFIGS = 'SET_MULTI_CONFIGS',
    GET_WLAN_SETTING = 'GET_WLAN_SETTING',
    WLAN_SETTING = 'WLAN_SETTING',
    UBUS = 'UBUS'
};

export enum WiFiCommand {
    GET_SITE_SURVEY = 'GET_SITE_SURVEY',
    GET_SITE_SURVEY_5G = 'GET_SITE_SURVEY_5G'
};

export interface CommandRequestBody {
    MAC: string;
    command: DeviceCommand | DeviceCommand[]
    data?: UpgradeFirmwareCommandBody |
    UpgradeConfigurationCommandBody |
    GetConfigurationCommandBody |
    PciLockCommandBody |
    RunDiagnosticCommandBody |
    RemoteAccessCommandBody |
    CbsdCommandBody |
    OpenWrtCommandBody |
    CbsdSetCpiPrivateKeyCommandBody |
    OpenWrtCommandWiFiBody |
    OpenwrtCommandUbusBody |
    UdpTestCommandBody;
};

export interface BackupRequestBody {
    fileName: string;
};

export enum BandType {
    LTE = 'lteband',
    NR = 'nrband',
};

export interface IP {
    ipaddress: string;
    port: string;
};