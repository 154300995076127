/******* Angular Resourse *******/
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

/******* Service *******/
import {
    Auth,
    Tenants,
    Users,
    Roles,
    Devices,
    Groups,
    Tags,
    Licenses,
    DeviceCmd,
    Files,
    RfStandard,
    Usages,
    ExternalRequestService,
    GeoPci,
    AlertCounter,
    AlertConfig,
    DeviceAlarm,
    CellHistory,
    Schedules,
    RestApi,
    ApiConsumers,
    AuditLogs,
    DomainProxyLogs,
    ProfileTemplate,
    DeviceReports,
    WebhookSettings,
    WebhookLogs
} from "@api/feature";

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule
    ]
})
export class APIModule {

    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: APIModule,
            providers: [
                Auth,
                Users,
                Tenants,
                Roles,
                Devices,
                Groups,
                Tags,
                Licenses,
                DeviceCmd,
                Files,
                RfStandard,
                Usages,
                ExternalRequestService,
                GeoPci,
                AlertCounter,
                AlertConfig,
                DeviceAlarm,
                CellHistory,
                Schedules,
                RestApi,
                ApiConsumers,
                AuditLogs,
                DomainProxyLogs,
                ProfileTemplate,
                DeviceReports,
                WebhookSettings,
                WebhookLogs
            ]
        };
    }
}

export * from './api.config';