import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FunctionsUsingCSI, KindOfCharacterAttributes, NgTerminal } from "ng-terminal";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

const THEME = {
    foreground: '#F8F8F8',
    background: '#2D2E2C',
    selectionBackground: '#5DA5D533',
    black: '#1E1E1D',
    brightBlack: '#262625',
    red: '#CE5C5C',
    brightRed: '#FF7272',
    green: '#5BCC5B',
    brightGreen: '#72FF72',
    yellow: '#CCCC5B',
    brightYellow: '#FFFF72',
    blue: '#5D5DD3',
    brightBlue: '#7279FF',
    magenta: '#BC5ED1',
    brightMagenta: '#E572FF',
    cyan: '#5DA5D5',
    brightCyan: '#72F0FF',
    white: '#F8F8F8',
    brightWhite: '#FFFFFF',
    border: '#85858a',
};

@Component({
    selector: 'becentral-terminal',
    templateUrl: './terminal.component.html',
    styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements AfterViewInit, OnDestroy {

    // Public
    @Input('title') title: string = "Welcome to SSH Connection";
    @ViewChild('term', { static: false }) terminal: NgTerminal;
    @Output('sendCommand') sendCommand: EventEmitter<string> = new EventEmitter<string>();

    // Private
    private _isTerminalInitial: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _isScreenLock: boolean = true;
    private _inputData$: BehaviorSubject<string>;
    private _resizeData$: BehaviorSubject<string>;
    private _unsubscribeAll$: Subject<any>;
    private _disposables: Array<any> = []

    /**
     * constructor
     */
    constructor() {
        this._inputData$ = new BehaviorSubject<string>(null);
        this._resizeData$ = new BehaviorSubject<string>(null);
        this._unsubscribeAll$ = new Subject();
    }

    // Accessor
    // -----------------------------------------------------------------------------------------------------

    /**
     * getter is terminal ready
     */
    get isTerminalInitial() {
        return this._isTerminalInitial;
    }

    /**
     * getter terminal instance
     */
    get instance() {
        return this.terminal?.underlying;
    }

    /**
     * getter input data stream
     */
    get onData() {
        return this._inputData$.pipe(
            filter(x => x != null),
            filter(() => !this._isScreenLock),
            takeUntil(this._unsubscribeAll$));
    }

    /**
     * getter resize data stream
     */
    get onResize() {
        return this._resizeData$.pipe(
            filter(x => x != null),
            takeUntil(this._unsubscribeAll$));
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * after view init
     */
    ngAfterViewInit(): void {
        this._initTerminal();
        this._isTerminalInitial.next(true);
    }

    /**
     * on destroy
     */
    ngOnDestroy(): void {
        this._dispose();
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    // Private Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initial terminal
     */
    private _initTerminal(): void {
        // Xterm Options
        this.terminal.setXtermOptions({
            fontSize: 16,
            fontFamily: '"Cascadia Code", Menlo, monospace',
            theme: THEME,
            cursorBlink: true,
        });
        this._disposables.push(this.terminal.underlying.onData((msg) => { this._inputData$.next(msg) }));
        this._disposables.push(this.terminal.underlying.onResize(size => {
            const msg = { type: 'winsize', cols: size.cols, rows: size.rows };
            this._resizeData$.next(JSON.stringify(msg));
            console.log(this.terminal.underlying.cols + 'x' + this.terminal.underlying.rows);
        }));
        this.writeIntoConsole(`${this.title}\n${FunctionsUsingCSI.cursorColumn(1)}`, KindOfCharacterAttributes.SetforegroundcolortoCyan);
        this.terminal.underlying.focus();
    }

    /**
     * dispose all
     */
    private _dispose(): void {
        this._disposables.forEach(instance => instance.dispose())
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * write message with options
     * 
     * @param {string} value 
     * @param {KindOfCharacterAttributes} foreColor 
     * @param {KindOfCharacterAttributes} background 
     */
    public writeIntoConsole(value: string, ...options: KindOfCharacterAttributes[]): void {
        options.forEach((option: KindOfCharacterAttributes) => {
            this.terminal.write(FunctionsUsingCSI.characterAttributes(option));
        });
        this.terminal.write(`${value}`);
    }

    /**
     * toggle screen lock
     */
    public toggleScreenLock(): void {
        this._isScreenLock = !this._isScreenLock;
    }

    /**
     * Clear current line
     */
    public clear(): void {
        this.terminal.underlying.clear();
    }
}