export interface WebhookDeviceAlertSettings {
    url: string;
    webhooksEvents: Array<string>;
};

export interface WebhookDeviceInformationSettings {
    url: string;
};

export interface WebhookSettings {
    name?: string;
    id?: string;
    CustID?: string;
    webhooksSecret: string;
    webhooksRejectUnauthorized: boolean;
    deviceAlertEnable: boolean;
    deviceAlertSettings: WebhookDeviceAlertSettings;
    deviceInfoEnable: boolean;
    deviceInfoSettings: WebhookDeviceInformationSettings;
};

export interface WebhookTestBody {
    webhooksURL: string;
    webhooksSecret: string;
    webhooksRejectUnauthorized: boolean;
    data: any;
};