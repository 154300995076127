<div [ngClass]="containerClass">
    <label [ngClass]="labelClass" [for]="id">{{label}}</label>
    <div [ngClass]="inputClass">
        <div class="custom-group radio-group">
            <ng-container *ngFor="let option of options">
                <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" [id]="id + '_' + option.value" [(ngModel)]='value'
                        [value]="option.value" />
                    <label class="custom-control-label" [for]="id + '_' + option.value">{{option.name}}</label>
                </div>
            </ng-container>
        </div>
    </div>
</div>