<button [@buttonDisplayTrigger]="loadMoreButtonDisplay" type="button"
    class="btn btn-icon rounded-circle btn-info load-more-btn" [disabled]="isLoading" (click)="loadMore($event)"
    rippleEffect>
    <ng-container *ngIf="isLoading else icon">
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </ng-container>
    <ng-template #icon>
        <span class="feather icon-chevrons-down"></span>
    </ng-template>
</button>