import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { buttonDisplayAnimation } from "@core/animations/core.animation";
import { fromEvent, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: 'load-more-button',
    templateUrl: './load-more-button.component.html',
    styleUrls: ['./load-more-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [buttonDisplayAnimation]
})
export class LoadMoreButtonComponent implements OnInit, OnDestroy, OnChanges {

    // Public 
    @Input('loadedCount') loadedCount: number = 0;
    @Input('totalCount') totalCount: number = 0;
    @Input('isLoading') isLoading: boolean = false;
    @Output('loadMoreEvent') loadMoreEvent: EventEmitter<any> = new EventEmitter<any>();
    resizeObserver: ResizeObserver | null = null;


    // Private
    private _loadMoreButtonDisplay: boolean = false;
    private _parentElement: Element;
    private _unsubscribeAll$: Subject<any>;

    /**
     * constructor
     * 
     * @param {ElementRef} _elementRef 
     */
    constructor(private _elementRef: ElementRef) {
        this._unsubscribeAll$ = new Subject<any>();
    }

    // Accessor
    // -----------------------------------------------------------------------------------------------------

    /**
     * get _loadMoreButtonDisplay
     */
    get loadMoreButtonDisplay() {
        return this._loadMoreButtonDisplay;
    }

    /**
     * get isLoadMoreAvailable
     */
    get isLoadMoreAvailable() {
        return this.loadedCount < this.totalCount && this.loadedCount != 0;
    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * on initial
     */
    ngOnInit(): void {
        this._parentElement = (this._elementRef.nativeElement as Element).parentElement;
        fromEvent(this._parentElement, 'scroll').pipe(takeUntil(this._unsubscribeAll$)).subscribe(() => {
            this._checklogsHeight();
        });
        this._checklogsHeight();
    }

    /**
     * on changes
     * @param {SimpleChanges} changes 
     */
    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.loadedCount || changes.totalCount) && this._parentElement) this._checklogsHeight();
    }

    /**
     * on destroy
     */
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    // Private Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * check logs table height
     */
    private _checklogsHeight(): void {
        setTimeout(() => {
            const scrollBottom = this._parentElement.scrollTop + this._parentElement.clientHeight;
            this._loadMoreButtonDisplay = this._parentElement.scrollHeight - scrollBottom < 50 && this.isLoadMoreAvailable;
        }, 0);
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * load more event handler
     * 
     * @param {Event} event 
     */
    public loadMore(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        if (this.isLoading) return;
        this.loadMoreEvent.emit();
    }
}