import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'static-text',
    templateUrl: './static-text.component.html',
    styleUrls: ['./form.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class StaticTextComponent {

    // Public
    @Input() label: string = "";
    @Input() id: string = "";
    @Input() text: string = "";
    @Input() disabled: boolean = false;
    @Input() nullMessage: string = "- empty -";
    @Input() textClass: 'text-danger' | 'text-success' | 'text-warning' | 'text-info' | 'text-basic' = 'text-basic';
    @Input() sameRow: boolean = false;

    // Accessor
    // -----------------------------------------------------------------------------------------------------

    get containerClass(): string {
        return this.sameRow ? 'form-group row' : 'static-text';
    }

    get labelClass(): string {
        return this.sameRow ? 'col-sm-4 col-form-label' : '';
    }

    get inputClass(): string {
        return this.sameRow ? 'col-sm-8 col-form-label' : '';
    }
}