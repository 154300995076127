import { DevcieTableDefination } from "@core/types";

export const GENERAL_COLUMN: Array<DevcieTableDefination> = [
    /* Model */
    {
        key: "MODEL",
        name: "Model",
        sortable: true,
        colvis: false,
        defaultDisplay: true,
        filterable: true,
        filterType: 'device',
        width: 130,
        class: "MODEL-Cell",
        placeholder: "Ex. MX-1000, MX-1200",
        searchable: true
    },
    /* Radio Type */
    {
        key: "IMEI",
        name: "Radio Type",
        sortable: false,
        colvis: true,
        defaultDisplay: true,
        filterable: true,
        filterType: 'network',
        custom: true,
        width: 130,
        class: "Radio-Type-Cell",
        searchable: false
    },
    /* MAC */
    {
        key: "MAC",
        name: "MAC",
        sortable: true,
        colvis: false,
        defaultDisplay: true,
        filterable: true,
        filterType: 'device',
        width: 130,
        class: "MAC-Cell",
        placeholder: "Ex. 600347XXXXX1, 600347XXXXX2",
        searchable: true
    },
    /* Name */
    {
        key: "name",
        name: "Name",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: true,
        filterType: 'device',
        width: 200,
        class: "name-Cell",
        placeholder: "Ex. BEC Units",
        searchable: true
    },
    /* Tags */
    {
        key: "tags",
        name: "Tags",
        sortable: false,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        filterType: 'device',
        custom: true,
        width: 200,
        class: "tags-Cell",
        searchable: false
    },
    /* Address */
    {
        key: "address",
        name: "Address",
        sortable: false,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        width: 250,
        class: "Address-Cell",
        searchable: true
    },
    /* Custom Field */
    {
        key: "customField",
        name: "Custom Field",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'device',
        width: 200,
        class: "customField-Cell",
        placeholder: "Ex. ABC",
        searchable: true
    },
    /* IP */
    {
        key: "IP",
        name: "IP",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: true,
        filterType: 'network',
        width: 130,
        class: "IP-Cell",
        placeholder: "Ex. 1.1.1.1, 2.2.2.2",
        searchable: true
    },
    /* IPv6 */
    {
        key: "IPV6",
        name: "IPv6",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 200,
        class: "IPV6-Cell",
        searchable: true
    },
    /* APN2 IP */
    {
        key: "APN2_IP",
        name: "APN2 IP",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        width: 140,
        class: "APN2_IP-Cell",
        searchable: true
    },
    /* FirmWare */
    {
        key: "FW",
        name: "F/W",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: true,
        filterType: 'hardware',
        width: 140,
        class: "FW-Cell",
        searchable: true
    },
    /* CFG */
    {
        key: "configuration",
        name: "CFG",
        sortable: false,
        colvis: true,
        defaultDisplay: true,
        filterable: false,
        custom: true,
        width: 50,
        class: "configuration-Cell",
        searchable: false
    },
    /* IMEI */
    {
        key: "IMEI",
        name: "IMEI",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 150,
        class: "IMEI-Cell",
        searchable: true
    },
    /* IMSI */
    {
        key: "IMSI",
        name: "IMSI",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 150,
        class: "IMSI-Cell",
        searchable: true
    },
    /* RSRP */
    {
        key: "RSRP",
        name: "RSRP",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: false,
        custom: true,
        width: 90,
        class: "RSRP-Cell",
        searchable: false
    },
    /* RSRP2 */
    {
        key: "RSRP2",
        name: "RSRP2",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "RSRP2-Cell",
        searchable: false
    },
    /* RSRP3 */
    {
        key: "RSRP3",
        name: "RSRP3",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "RSRP3-Cell",
        searchable: false
    },
    /* NR RSRP */
    {
        key: "NR_RSRP",
        name: "NR RSRP",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "NR_RSRP-Cell",
        searchable: false
    },
    /* RSRQ */
    {
        key: "RSRQ",
        name: "RSRQ",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: false,
        custom: true,
        width: 90,
        class: "RSRQ-Cell",
        searchable: false
    },
    /* RSRQ2 */
    {
        key: "RSRQ2",
        name: "RSRQ2",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "RSRQ2-Cell",
        searchable: false
    },
    /* RSRQ3 */
    {
        key: "RSRQ3",
        name: "RSRQ3",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "RSRQ3-Cell",
        searchable: false
    },
    /* NR RSRQ */
    {
        key: "NR_RSRQ",
        name: "NR RSRQ",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "NR_RSRQ-Cell",
        searchable: false
    },
    /* RSSI */
    {
        key: "RSSI",
        name: "RSSI",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: false,
        custom: true,
        width: 90,
        class: "RSSI-Cell",
        searchable: false
    },
    /* RSSI2 */
    {
        key: "RSSI2",
        name: "RSSI2",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "RSSI2-Cell",
        searchable: false
    },
    /* RSSI3 */
    {
        key: "RSSI3",
        name: "RSSI3",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "RSSI3-Cell",
        searchable: false
    },
    /* SINR */
    {
        key: "SINR",
        name: "SINR",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: false,
        custom: true,
        width: 90,
        class: "SINR-Cell",
        searchable: false
    },
    /* SINR2 */
    {
        key: "SINR2",
        name: "SINR2",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "SINR2-Cell",
        searchable: false
    },
    /* SINR3 */
    {
        key: "SINR3",
        name: "SINR3",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "SINR3-Cell",
        searchable: false
    },
    /* NR SINR */
    {
        key: "NR_SINR",
        name: "NR SINR",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 90,
        class: "NR_SINR-Cell",
        searchable: false
    },
    /* NETWORK */
    {
        key: "NETWORK",
        name: "NETWORK",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: true,
        filterType: 'network',
        width: 200,
        class: "NETWORK-Cell",
        placeholder: "Ex. BEC Network",
        searchable: true
    },
    /* MDN */
    {
        key: "LTE_MDN",
        name: "MDN",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 200,
        class: "MDN-Cell",
        placeholder: "Ex. MDN",
        searchable: true
    },
    /* PCI */
    {
        key: "PCI",
        name: "PCI",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: true,
        filterType: 'network',
        width: 90,
        class: "PCI-Cell",
        searchable: true
    },
    /* ECI */
    {
        key: "ECI", // "ECI",
        name: "ECI",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 150,
        class: "ECI-Cell",
        searchable: true
    },
    /* CELL_ID */
    {
        key: "CELL_ID",
        name: "Sector ID",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 150,
        class: "CELL_ID-Cell",
        searchable: true
    },
    /* ENB ID */
    {
        key: "ENB_ID",
        name: "eNB", // "eNB/gNB",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 150,
        class: "ENB_ID-Cell",
        searchable: true
    },
    /* NCI */
    {
        key: "NCI", // "NCI",
        name: "NCI",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 150,
        class: "NCI-Cell",
        searchable: true
    },
    /* NR_CELL_ID */
    {
        key: "NR_CELL_ID",
        name: "NR Sector ID",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 150,
        class: "NR_CELL_ID-Cell",
        searchable: true
    },
    /* ENB ID */
    {
        key: "GNB_ID",
        name: "gNB",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 150,
        class: "GNB_ID-Cell",
        searchable: true
    },
    /* BAND */
    {
        key: "BAND",
        name: "BAND",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: true,
        filterType: 'network',
        width: 100,
        class: "BAND-Cell",
        searchable: true
    },
    /* CardFw */
    {
        key: "CardFw",
        name: "CardFw",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'hardware',
        width: 250,
        class: "CardFw-Cell",
        searchable: true
    },
    /* RI */
    {
        key: "RI",
        name: "RI",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 100,
        class: "RI-Cell",
        searchable: true
    },
    /* DL MCS */
    {
        key: "DL_MCS",
        name: "DL MCS",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 120,
        class: "DL_MCS-Cell",
        searchable: true
    },
    /* CQI */
    {
        key: "CQI",
        name: "CQI",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 100,
        class: "CQI-Cell",
        searchable: true
    },
    /* NR CQI */
    {
        key: "NR_CQI",
        name: "NR CQI",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'network',
        width: 100,
        class: "NR_CQI-Cell",
        searchable: true
    },
    /* ICCID */
    {
        key: "ICCID",
        name: "ICCID",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        width: 200,
        class: "ICCID-Cell",
        searchable: true
    },
    /* CBSD STATUS */
    {
        key: "CBSD_STATUS",
        name: "CBSD",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        width: 200,
        class: "CBSD_STATUS-Cell",
        searchable: false
    },
    /* LTEUPTIME */
    {
        key: "LTE_UPTIME",
        name: "CONN. UPTIME",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 150,
        class: "LTE_UPTIME-Cell",
        searchable: false
    },
    /* SYSUPTIME */
    {
        key: "SYSUPTIME",
        name: "SYS. UPTIME",
        sortable: true,
        colvis: true,
        defaultDisplay: true,
        filterable: false,
        custom: true,
        width: 150,
        class: "SYSUPTIME-Cell",
        searchable: false
    },
    /* Last Connect */
    {
        key: "lastPublishedAt",
        name: "Last Connect",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: false,
        custom: true,
        width: 200,
        class: "LastConnect-Cell",
        searchable: false
    }
];

export const DEVICE_DATA_TABLE_DEF_GROUP: Array<DevcieTableDefination> = [
    /* GROUP */
    {
        key: "groupId",
        name: "Group",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'device',
        custom: true,
        width: 120,
        class: "group-Cell",
        searchable: false
    }
];

export const DEVICE_DATA_TABLE_DEF_OWNER: Array<DevcieTableDefination> = [
    /* OWNER */
    {
        key: "CustID",
        name: "Owner",
        sortable: true,
        colvis: true,
        defaultDisplay: false,
        filterable: true,
        filterType: 'device',
        custom: false,
        width: 120,
        class: "owner-Cell",
        searchable: true
    }
];

// Device's property key map to property name 
export const DEVICE_KEY_NAME_MAPPIGN: { [key: string]: string } = {
    Status: "Status",
    MODEL: "Model",
    BAND: "BAND",
    CELL_ID: "Sector ID",
    CQI: "CQI",
    NR_CQI: "NR CQI",
    CardFw: "CardFw",
    DL_MCS: "DL MCS",
    ECI: "ECI",
    ENB_ID: "eNB",
    NCI: "NCI",
    GNB_ID: "gNB",
    FW: "F/W",
    IMEI: "IMEI",
    IMSI: "IMSI",
    IP: "IP",
    IPV6: "IPV6",
    MAC: "MAC",
    NETWORK: "NETWORK",
    LTE_MDN: "MDN",
    PCI: "PCI",
    RI: "RI",
    customField: "Custom Field",
    name: "Name",
    CustID: "Owner",
    radioType: "Radio Type",
    groupId: "Group"
};
