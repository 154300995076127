/******* Angular Resourse *******/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/******* Plug-In Resourse *******/
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/******* Core Resourse *******/
import { CoreCommonModule } from '@core/common.module';

/******* Component Resourse *******/
import { TextInputComponent } from './text-input.component';
import { PasswordInputComponent } from './password-input.component';
import { TermsOfUseInputComponent } from './terms-of-use-input.component';
import { SelectInputComponent } from './select-input.component';
import { CheckboxInputComponent } from './checkbox-input.component';
import { NumberInputComponent } from './number-input.component';
import { TextAreaInputComponent } from './text-area-input.component';
import { StaticTextComponent } from './static-text.component';
import { RadioInputComponent } from './radio-input.component';

@NgModule({
    declarations: [
        TextInputComponent,
        PasswordInputComponent,
        TermsOfUseInputComponent,
        SelectInputComponent,
        CheckboxInputComponent,
        NumberInputComponent,
        TextAreaInputComponent,
        StaticTextComponent,
        RadioInputComponent
    ],
    imports: [CommonModule, NgbModule, CoreCommonModule],
    exports: [
        TextInputComponent,
        PasswordInputComponent,
        TermsOfUseInputComponent,
        SelectInputComponent,
        CheckboxInputComponent,
        NumberInputComponent,
        TextAreaInputComponent,
        StaticTextComponent,
        RadioInputComponent
    ]
})
export class FormElementModule { }