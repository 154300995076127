import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HttpCancelService {

    private pendingHTTPRequests$ = new Subject<void>();

    /**
     * Cancel Pending HTTP calls
     */
    public cancelPendingRequests(): void {
        this.pendingHTTPRequests$.next();
    }

    /**
     * Observable Item for Cancel Pending request call
     * 
     * @returns {Observable<any>}
     */
    public onCancelPendingRequests(): Observable<any> {
        return this.pendingHTTPRequests$.asObservable();
    }

}